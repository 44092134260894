<style scoped>
    @import '../assets/css/notFound.scss'; 
</style>
<template>
    <div class="notFound__container">
        <div>
            <div class="notFound__center notFound__home__link">
                    Try going Home&nbsp;<router-link to="/">Here</router-link>
            </div>
            <div class="notFound__backdrop">
                <p class="notFound__text">You are lost.</p>
            </div>
        </div>
    </div>
</template>

<script>
export default {  
    name: 'NotFound',

}
</script>
